import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import './App.css';

import TimerBody from './containers/TimerBody'

import Nav from './components/Nav'
import About from './components/About'
import Footer from './components/Footer'

import restIdeas from './restIdeas'
import alarmDing from './assets/Ding-sound-effect.mp3'
import click from './assets/Clicking-sound-effect.mp3'

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      timeRemaining: 1500,
      timerIsRunning: false,
      timerType: "focus",
      restIdeas: [],
      cycles: 0,
      isAboutClicked: false,
    }
    ReactGA.initialize('UA-145479778-3');
    ReactGA.pageview(window.location.pathname);
    this.myRef = React.createRef()  
  }

  setReactGAClickEvent = (timerName) => {
    ReactGA.event({
      category: 'User',
      action: `Click ${timerName}`
    });
  }

  startTimer = 0
  
  getRestIdeas = () => {
    let restIdeas1=restIdeas[Math.floor(Math.random()*restIdeas.length)]
    let restIdeas2=restIdeas[Math.floor(Math.random()*restIdeas.length)]
    let restIdeas3=restIdeas[Math.floor(Math.random()*restIdeas.length)]
    return [restIdeas1, restIdeas2, restIdeas3]
  }

  playAudioClip = (audioClip) => {
    let audio = new Audio(audioClip);
    return (
      audio.play()
     )
   }
   
  incrementCycles = () => {
    this.setState(state=>({
      cycles: state.cycles+1
    }))
  }

  switchFromFocus = () => {
    this.incrementCycles()
    if(this.state.cycles%4===0){
      this.onTimerTypeClick('long rest', 900)
    } else {
      this.onTimerTypeClick('short rest', 300)
    }
  }

  handleCountdownEnd = () => {
    const { timerType } = this.state;
    this.playAudioClip(alarmDing)
    this.pauseTime()
    if(timerType==="focus"){
      this.switchFromFocus()
    }
    else if(timerType==="short rest"||timerType==="long rest"){   
      this.onTimerTypeClick('focus', 1500)
    }
  }

  reduceTime=()=>{
    const { timeRemaining } = this.state;
    if(timeRemaining===0){
      this.handleCountdownEnd()
    } else {
      this.setState((prevState)=>({
        timeRemaining: prevState.timeRemaining-1
      }))  
    }
  }

  pauseTime=()=>{
    clearInterval(this.startTimer)
    this.setState({
      timerIsRunning:false
    })
  }

  startTime=()=>{
    this.setState({timerIsRunning:true})
    this.startTimer = setInterval(this.reduceTime, 1000)
  }

  timer=()=>{
    console.log('timer')
    ReactGA.event({
      category: 'User',
      action: 'Start Timer'
    });
    this.playAudioClip(click)
    this.handleStartAndPause()
  }
  
  handleStartAndPause = () => {
    if(this.state.timerIsRunning){
      this.pauseTime()
    } else {
      this.startTime()
    }
  }
  
  setTimerTypeState = (timerType, timeRemaining) => {
    const restIdeas = this.getRestIdeas()
    this.setState(()=>({
      timerType,
      timeRemaining,
      restIdeas
    }))
  }

  onTimerTypeClick = (timerType, timeRemaining) => {
    this.setReactGAClickEvent(timerType)
    this.pauseTime()
    this.setTimerTypeState(timerType, timeRemaining)
  }

  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop)   

  onAboutClick = () => {
    if(!this.state.isAboutClicked){
      this.scrollToMyRef()
    }
    this.setState(prevState=>({
      ...prevState,
      isAboutClicked: !prevState.isAboutClicked
    }))
  }

  render(){
    const { timerType, restIdeas, timerIsRunning, isAboutClicked, timeRemaining, cycles } = this.state;
    let min = Math.floor(timeRemaining / 60)
    let sec = timeRemaining%60
    let secDis = sec===0 ? `${sec}0` : (sec<10 ? `0${sec}`: sec)
    const timerIsRunningTitle = `${min.toString()}:${secDis.toString()} - Time to ${timerType[0].toUpperCase()}${timerType.slice(1)}!`
    const timerIsNotRunningTitle = "Pomodoro Timer"
    const title = timerIsRunning ? timerIsRunningTitle : timerIsNotRunningTitle
    const timerTypeClass = timerType.split(" ").join("")
    const aboutClass = isAboutClicked ? "description-visible" : "description-hidden-"+timerTypeClass
    return (
      <div className = {timerType==="focus" ? "App-focus" : timerType==="short rest" ? "App-short-rest" : "App-long-rest"}>
         <Helmet defer={false} title={title}>
          <title>{title}</title>
          <meta 
            name="description" 
            content="A Pomodoro productivity app. Designed to help you focus. Simple, clean, mobile and desktop optimized. Includes little ideas for how to get the most from your rest time, too. Stay focused."
          ></meta>
        </Helmet>
        <div className="main-body">
          <Nav onAboutClick={this.onAboutClick}/>
          <TimerBody 
            timerType={timerType}
            onTimerTypeClick={this.onTimerTypeClick}
            min={min}
            secDis={secDis}
            timer={this.timer}
            timerIsRunning={timerIsRunning}
            restIdeas={restIdeas}
            cycles={cycles}
          />
        </div>
        <div ref={this.myRef} >
          <About aboutClass={aboutClass} />
        </div>
        <Footer />
      </div>
    );  
  }
}

export default App;
