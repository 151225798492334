import React from 'react'

const Footer = () => {
    return (
        <div id="footer">
            <hr className="footer-hr"/>
            <div className="copyright">
                PomodoroWork © 2020
            </div>
        </div>
    )
}

export default Footer